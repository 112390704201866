const awsmobile = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:dcce2911-2467-4c15-8f7e-887517cc220e",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_7yVWAKk6Q",
  aws_user_pools_web_client_id: "2tu23n500nfpoi7gk7679nto79",
  oauth: {}
};
export default awsmobile;
